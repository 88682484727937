.chatPage{
    background-color: #95b6b680;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chatContainer{
    background-color: white;
    height: 60%;
    width: 50%;
}

.header{
    background-color: crimson;
    height: 15%;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.header>h2{
    font-size: 1.5vmax;
    color: white;
    margin: 2.5vmax;
}

.header img{
    
    margin: 2.5vmax;
    transition: all 0.3s;
    cursor: pointer;
}
.header>img:hover{
    transform: scale(1.2);
}


.chatBox{

 height: 70%;
 box-sizing: border-box;
 
}

.inputBox{
    
    height: 15%;
    box-sizing: border-box;
    display: flex;
}

#chatInput{
   
    width: 80%;
    border: none;
    padding: 2vmax;
    box-sizing: border-box;
    background-color: white;
    outline: none;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.2vmax;
    border-top: 0.2vmax solid rgba(41, 41, 41, 0.562);
}


.sendBtn{
    background-color: crimson;
    color: white;
    border: none;
    width: 21%;
    transition: all 0.3s;
    cursor: pointer;
}

.sendBtn>img{
    width: 2.6vmax;
    filter:brightness(100) invert();
    transition: all 0.3s;
}
.sendBtn:hover{
    background-color: rgb(156, 13, 42);
} 
.sendBtn:hover img{
    transform: translateX(10px);
}