body {
  margin: 0;
}

@media only screen and (max-width: 480px) {
  .mhide{
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    z-index: 10000 !important;
  }
}

/* ADMIN Dashboard */

.home-admin {
  margin: 45px;
  border-radius: 30px;
}

@media only screen and (max-width: 600px) {
  .home-admin {
    margin: 0;
    padding-bottom: 20px;
    padding-top: 20px;
    background-color: white;
  }
}

/* Tabs Backend */
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #ffffff !important;
  background-color: #5cb85c !important;
}
.nav-tabs > li > a {
  border-radius: 5px !important;
}
.nav > li > a {
  border-color: #109d58 !important;
  color: darkgreen !important;
}
.nav-tabs {
  border-bottom: none !important;
}

/* Chat C */
@media (max-aspect-ratio: 7/10) {
  .JoinPage {
    display: flex;
    justify-content: center;
    align-items: flex-start !important;
  }
  .JoinContainer {
    margin-top: 30vw !important;
    width: 100% !important;
  }

  .JoinContainer > img {
    width: 30vw !important;
  }
  .JoinContainer > h1 {
    font-size: 6vw !important;
    width: 30% !important;
    border-bottom: 0.6vw solid #de0f3f !important;
    padding: 3vw !important;
  }

  #joinInput {
    width: 85vw !important;
    padding: 4vw !important;
    border-radius: 15px;
    font-size: 5vw !important;
    margin: 5vw !important;
  }
  .joinbtn {
    width: 40vw !important;
    padding: 3vw !important;
    font-size: 6vw !important;
    border-radius: 10px;
  }

  .chatContainer {
    background-color: white;
    height: 100% !important;
    width: 100% !important;
  }
  .header {
    height: 10% !important;
  }
  .header > h2 {
    margin: 8vw !important;
    font-size: 5vw !important;
  }

  .header img {
    margin: 6vw !important;
    transform: scale(2) !important;
  }
  .header > img:hover {
    transform: scale(3.2) !important;
  }
  .chatBox {
    height: 80% !important;
  }

  .inputBox {
    height: 10% !important;
  }
  #chatInput {
    padding: 4vw !important;
    font-size: 4vw !important;
  }
  .messageBox {
    padding: 3vw !important;
    margin: 3vw !important;
    border-radius: 1.5vw !important;
    font-size: 3.7vw !important;
  }
}

.bottom-button {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 40px;
  background-color: #109d58;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 1px 2px 1px rgba(79, 79, 79, 0.45);
  right: 15px;
  z-index: 100;
}
.bottom-button:hover {
  color: #fff;
  text-decoration: none;
  width: 65px;
  height: 65px;
  bottom: 40px;
  font-size: 45px;
  box-shadow: 1px 2px 1px rgb(100, 100, 100);
}
.bottom-button:focus {
  color: #fff;
}

.my-float {
  margin-top: 10px;
}

/* 404 */

._404 {
  margin: auto;
  margin-top: 90px;
  margin-bottom: 0;
  font-family: system-ui;
}

._404 .text {
  font-family: monospace;
  font-weight: bolder;
  font-size: 70px;
  color: gray;
}

._404 .sans-serif {
  font-family: sans-serif;
}

._404btn {
  margin-top: 40px;
  padding-left: 25px;
  padding-right: 25px;
  font-weight: 500;
}
@media only screen and (max-width: 600px) {
  ._404 {
    margin: auto;
    margin-top: 50px;
  }
}

.mx-1 {
  margin-left: 5px;
  margin-right: 5px;
}

/* Spinner */

.loader {
  background-color: #d4d4d46a;
  position: fixed;
  width: 100vw;
  height: 100vh;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}

/* admin nav */
.fa-fw{
  margin-right: 10px;
}
.admin-nav {
  scroll-behavior: smooth;
  overflow-y: overlay;
  height: 93vh;
  scrollbar-width: 5px;
  background-color: ghostwhite;

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
    min-height: 93vh;

}

.admin-nav::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
  overflow-y: scroll;
  height: 93vh;
  width: 5px;
  min-height: 93vh;
}

.admin-nav::-webkit-scrollbar-track {
  scrollbar-width: inherit;
}

#page-wrapper {
  height: 93vh;
  overflow-y: overlay;
  border-left: 5px solid lightgray !important;

  -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll; 
    min-height: 93vh !important;
}

#page-wrapper::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
  overflow-y: scroll;
  height: 93vh;
  width: 5px;
  min-height: 93vh !important;
}

.page-header {
  color: orange;
  text-align: center;
  font-family: cursive;
  border-bottom: none !important;
  font-size: 28px;
}

.addBtns{
  background-color: darkorange;
  color: white;
  padding: 5px 15px;
  margin: 0 5px;
  border: 1px solid white;
  border-radius: 7px;
  float: right;
  position: relative;
  bottom: 6px;
}

.addBtns:hover{
  background-color: orange;
  color: white;
  border: 1px solid darkorange;

}

@media only screen and (max-width: 480px) {
  #page-wrapper{
    border-left: 0!important;
    overflow-x: hidden;
  }
  .form-control{
    width: 100% !important; /* For Admin Blood System*/
  }
  .ml{
    margin-left: 0 !important;
  }
}



.scroll {
  -ms-overflow-style: none; /* Edge, Internet Explorer */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
  }
  .scroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
  }
  .scroll {
  -ms-overflow-style: none; /* Edge, Internet Explorer */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
  }
  .scroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
  }

  .pName{
    font-variant-caps: small-caps;
    font-weight: 500;
    margin-left: 10px;
    color: white;
  }